import React from 'react';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, useIntl } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { formatMoney } from '../../util/currency';
import { ensureListing } from '../../util/data';
import { richText } from '../../util/richText';

import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';

import imagePlaceholder from '../../assets/imagePlaceholder.png';
import { Icons, ImageSlider, NamedLink, ResponsiveImage } from '../../components';
import { getProductImages, isArrayLength, isInstantBooking } from '../../util/genericHelpers';
import {
  getBoatPrice,
  getPriceAndAssignListingType,
  validateCustomListingPrice,
} from '../../util/dataExtractors';
import { currentUserSelector } from '../../ducks/user.duck';
import { useSelector } from 'react-redux';

import css from './ListingCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;
const isMobile = typeof window !== 'undefined' && window.innerWidth < 768;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const PriceMaybe = props => {
  const { price, publicData, reformedPrice, config, intl, listing } = props;
  const { listingType } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);

  if (!showPrice && price) {
    return null;
  }

  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);
  const { priceTitle } = priceData(price, config.currency, intl);

  const customPrice = validateCustomListingPrice(price);

  return (
    <div className={css.price}>
      <div className={css.priceValue} title={priceTitle}>
        ${customPrice ? Number(customPrice?.amount / 100).toFixed(2) : reformedPrice.toFixed(2)}
      </div>
      {isBookable ? (
        <div className={css.perUnit}>
          <FormattedMessage id="ListingCard.perUnit" />
        </div>
      ) : null}
    </div>
  );
};

/**
 * ListingCard
 *
 * @component
 * @param {Object} props
 * @param {string?} props.className add more style rules in addition to component's own css.root
 * @param {string?} props.rootClassName overwrite components own css.root
 * @param {Object} props.listing API entity: listing or ownListing
 * @param {string?} props.renderSizes for img/srcset
 * @param {Function?} props.setActiveListing
 * @param {boolean?} props.showAuthorInfo
 * @returns {JSX.Element} listing card to be used in search result panel etc.
 */
export const ListingCard = props => {
  const config = useConfiguration();
  const intl = useIntl();
  const { className, rootClassName, listing } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const slug = createSlug(title);
  const currentUser = useSelector(currentUserSelector);

  let images = getProductImages(currentListing?.images);
  const isInfoOnly = publicData?.infoOnly;

  if (!isArrayLength(images)) {
    images = [imagePlaceholder];
  }

  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      {isArrayLength(images) ? (
        <div className={css.imageContainer}>
          <ImageSlider
            images={images}
            isMobile={isMobile}
            imgAlt={''}
            pathParams={{ id, slug }}
            pushToListingPage={() => {}}
          />
          {isInstantBooking(listing) ? (
            <div className={css.badge}>
              <Icons name="instantBooking" /> <FormattedMessage id="ListingCard.instantBooking" />
            </div>
          ) : null}
        </div>
      ) : null}
      <div className={css.info}>
        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
        </div>

        <div className={css.ratingPrice}>
          {isInfoOnly ? (
            <div className={css.infoOnly}>
              <FormattedMessage id="ListingCard.notReservableOnline" />{' '}
            </div>
          ) : (
            <PriceMaybe
              listing={listing}
              reformedPrice={getBoatPrice(listing, currentUser)}
              price={price}
              publicData={publicData}
              config={config}
              intl={intl}
            />
          )}
        </div>
      </div>
    </NamedLink>
  );
};

export default ListingCard;
