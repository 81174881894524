// These helpers are calling this template's own server-side routes
// so, they are not directly calling Marketplace API or Integration API.
// You can find these api endpoints from 'server/api/...' directory

import appSettings from '../config/settings';
import { types as sdkTypes, transit } from './sdkLoader';
import Decimal from 'decimal.js';

export const apiBaseUrl = marketplaceRootURL => {
  const port = process.env.REACT_APP_DEV_API_SERVER_PORT;
  const useDevApiServer = process.env.NODE_ENV === 'development' && !!port;

  // In development, the dev API server is running in a different port
  if (useDevApiServer) {
    return `http://localhost:${port}`;
  }

  // Otherwise, use the given marketplaceRootURL parameter or the same domain and port as the frontend
  return marketplaceRootURL ? marketplaceRootURL.replace(/\/$/, '') : `${window.location.origin}`;
};

// Application type handlers for JS SDK.
//
// NOTE: keep in sync with `typeHandlers` in `server/api-util/sdk.js`
export const typeHandlers = [
  // Use Decimal type instead of SDK's BigDecimal.
  {
    type: sdkTypes.BigDecimal,
    customType: Decimal,
    writer: v => new sdkTypes.BigDecimal(v.toString()),
    reader: v => new Decimal(v.value),
  },
];

const serialize = data => {
  return transit.write(data, { typeHandlers, verbose: appSettings.sdk.transitVerbose });
};

const deserialize = str => {
  return transit.read(str, { typeHandlers });
};

const methods = {
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
};

// If server/api returns data from SDK, you should set Content-Type to 'application/transit+json'
const request = (path, options = {}) => {
  const url = `${apiBaseUrl()}${path}`;
  const { credentials, headers, body, ...rest } = options;

  // If headers are not set, we assume that the body should be serialized as transit format.
  const shouldSerializeBody =
    (!headers || headers['Content-Type'] === 'application/transit+json') && body;
  const bodyMaybe = shouldSerializeBody ? { body: serialize(body) } : {};

  const fetchOptions = {
    credentials: credentials || 'include',
    // Since server/api mostly talks to Marketplace API using SDK,
    // we default to 'application/transit+json' as content type (as SDK uses transit).
    headers: headers || { 'Content-Type': 'application/transit+json' },
    ...bodyMaybe,
    ...rest,
  };

  return window.fetch(url, fetchOptions).then(res => {
    const contentTypeHeader = res.headers.get('Content-Type');
    const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;

    if (res.status >= 400) {
      return res.json().then(data => {
        let e = new Error();
        e = Object.assign(e, data);

        throw e;
      });
    }
    if (contentType === 'application/transit+json') {
      return res.text().then(deserialize);
    } else if (contentType === 'application/json') {
      return res.json();
    }
    return res.text();
  });
};

// Keep the previous parameter order for the post method.
// For now, only POST has own specific function, but you can create more or use request directly.
const post = (path, body, options = {}) => {
  const requestOptions = {
    ...options,
    method: methods.POST,
    body,
  };

  return request(path, requestOptions);
};

/**
 * Performs a GET request to the specified path.
 *
 * @param {string} path
 * @param {Object} [options={}]
 * @returns {Promise}
 */
const get = (path, options = {}) => {
  const requestOptions = {
    ...options,
    method: methods.GET,
  };
  return request(path, requestOptions);
};

// Fetch transaction line items from the local API endpoint.
//
// See `server/api/transaction-line-items.js` to see what data should
// be sent in the body.
export const transactionLineItems = body => {
  return post('/api/transaction-line-items', body);
};

// Initiate a privileged transaction.
//
// With privileged transitions, the transactions need to be created
// from the backend. This endpoint enables sending the order data to
// the local backend, and passing that to the Marketplace API.
//
// See `server/api/initiate-privileged.js` to see what data should be
// sent in the body.
export const initiatePrivileged = body => {
  return post('/api/initiate-privileged', body);
};

// Transition a transaction with a privileged transition.
//
// This is similar to the `initiatePrivileged` above. It will use the
// backend for the transition. The backend endpoint will add the
// payment line items to the transition params.
//
// See `server/api/transition-privileged.js` to see what data should
// be sent in the body.
export const transitionPrivileged = body => {
  return post('/api/transition-privileged', body);
};

// Create user with identity provider (e.g. Facebook or Google)
//
// If loginWithIdp api call fails and user can't authenticate to Marketplace API with idp
// we will show option to create a new user with idp.
// For that user needs to confirm data fetched from the idp.
// After the confirmation, this endpoint is called to create a new user with confirmed data.
//
// See `server/api/auth/createUserWithIdp.js` to see what data should
// be sent in the body.
export const createUserWithIdp = body => {
  return post('/api/auth/create-user-with-idp', body);
};

export const uploadObject = body => {
  return post('/api/upload-object', body);
};

export const updateTxImageReviews = body => {
  return post('/api/update-image-reviews', body);
};

export const createPaymentIntent = body => {
  return post('/api/create-payment-intent', body);
};

export const updateTxMetadata = body => {
  return post('/api/update-metadata', body);
};

export const superhogScreeningApi = body => {
  return post('/api/superhog-screening', body);
};
export const cancelSuperhogScreeningApi = body => {
  return post('/api/cancel-superhog-screening', body);
};

export const updateLastSeenMetadata = body => {
  return post('/api/update-last-seen', body);
};

export const fetchBlogs = body => {
  return post('/api/strapi/fetch-blogs', body);
};

export const fetchBlog = body => {
  return post('/api/strapi/fetch-blog', body);
};

export const fetchSeoCategory = body => {
  return post('/api/strapi/fetch-seo-category', body);
};

export const fetchTagsFilter = body => {
  return post('/api/strapi/fetch-blogs-tags', body);
};

export const fetchSeoPages = body => {
  return post('/api/strapi/fetch-seo-categories', body);
};

export const fetchHelpPages = body => {
  return post('/api/strapi/fetch-help-pages', body);
};

export const fetchUsersDetails = body => {
  return get(`/api/sharetribe/users/fetch-details?page=${body}`);
};
export const fetchListingDetails = body => {
  return get(`/api/sharetribe/listings/fetch-details?page=${body}`);
};
export const fetchMessagesDetails = body => {
  return get(`/api/sharetribe/messages/fetch-details?page=${body}`);
};
export const fetchTransactionsDetails = body => {
  return get(`/api/sharetribe/transactions/fetch-details?page=${body}`);
};
export const fetchHostsStripeDetails = body => {
  return get(`/api/sharetribe/users/fetch-stripe-details?page=${body}`);
};
export const fetchHostListingsSummary = body => {
  return get(`/api/sharetribe/users/fetch-host-listings-summary?page=${body}`);
};
export const transactionTransition = body => {
  return post(`/api/sharetribe/transaction/transition`, body);
};

// Twilio
export const sendOneTimePassword = body => {
  return post(`/api/twilio/send-otp`, body);
};

export const validateOneTimePassword = body => {
  return post(`/api/twilio/verify-otp`, body);
};

// Sendgrid
export const addContactSendgridApi = body => {
  return post(`/api/sendgrid/add-contact`, body);
};

//  Mailchimp
export const addContactMailchimpApi = body => {
  return post(`/api/mailchimp/add-contact`, body);
};


