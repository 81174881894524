import { Flip, toast } from 'react-toastify';

export const onTriggerError = text => {
  toast.error(text, {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
    transition: Flip,
  });
};

export const onTriggerSuccess = text => {
  toast.success(text, {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
    transition: Flip,
  });
};
