import React from 'react';
import classNames from 'classnames';
import { Field } from 'react-final-form';

import css from './FieldCheckbox.module.css';

/**
 * IconCheckbox
 *
 * @component
 * @param {Object} props
 * @param {string?} props.className add more style rules in addition to components own css.root
 * @param {string?} props.checkedClassName overwrite components own css.checked
 * @param {string?} props.boxClassName overwrite components own css.box
 * @returns {JSX.Element} checkbox svg that places the native checkbox
 */
const IconCheckbox = props => {
  const { className, checkedClassName, boxClassName } = props;
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        className={boxClassName || css.box}
        x="0.5"
        y="0.5"
        width="15"
        height="15"
        rx="3.5"
        stroke="#051036"
      />
      <rect
        className={checkedClassName || css.checked}
        width="16"
        height="16"
        rx="4"
        fill="#2E95BF"
      />
      <g clipPath="url(#clip0_169_6449)">
        <path
          className={checkedClassName || css.checked}
          d="M11.8141 5.30766C11.5665 5.0598 11.1646 5.05996 10.9167 5.30766L6.87823 9.34632L5.08329 7.55139C4.83544 7.30354 4.43368 7.30354 4.18583 7.55139C3.93798 7.79924 3.93798 8.20101 4.18583 8.44886L6.42941 10.6924C6.55326 10.8163 6.71565 10.8784 6.87806 10.8784C7.04047 10.8784 7.20303 10.8164 7.32687 10.6924L11.8141 6.2051C12.0619 5.95742 12.0619 5.55549 11.8141 5.30766Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_169_6449">
          <rect
            className={checkedClassName || css.checked}
            width="8"
            height="8"
            fill="white"
            transform="translate(3.99994 4)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
<svg
  width="16"
  height="16"
  viewBox="0 0 16 16"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
></svg>;

/**
 * Final Form Field containing checkbox input
 *
 * @component
 * @param {Object} props
 * @param {string?} props.className add more style rules in addition to components own css.root
 * @param {string?} props.rootClassName overwrite components own css.root
 * @param {string?} props.svgClassName is passed to checkbox svg as className
 * @param {string?} props.textClassName overwrite components own css.textRoot given to label
 * @param {string} props.id givent to input
 * @param {string} props.name Name groups several checkboxes to an array of selected values
 * @param {string} props.value Checkbox needs a value that is passed forward when user checks the checkbox
 * @param {ReactNode} props.label
 * @returns {JSX.Element} Final Form Field containing checkbox input
 */
const FieldCheckbox = props => {
  const {
    rootClassName,
    className,
    svgClassName,
    textClassName,
    id,
    label,
    useSuccessColor,
    ...rest
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  // This is a workaround for a bug in Firefox & React Final Form.
  // https://github.com/final-form/react-final-form/issues/134
  const handleOnChange = (input, event) => {
    const { onBlur, onChange } = input;
    onChange(event);
    onBlur(event);

    // If onChange has been passed as a props to FieldCheckbox
    if (rest.onChange) {
      rest.onChange(event);
    }
  };

  const successColorVariantMaybe = useSuccessColor
    ? {
        checkedClassName: css.checkedSuccess,
        boxClassName: css.boxSuccess,
      }
    : {};

  return (
    <span className={classes}>
      <Field type="checkbox" {...rest}>
        {props => {
          const input = props.input;
          return (
            <input
              id={id}
              className={css.input}
              {...input}
              onChange={event => handleOnChange(input, event)}
            />
          );
        }}
      </Field>
      <label htmlFor={id} className={css.label}>
        <span className={css.checkboxWrapper}>
          <IconCheckbox className={svgClassName} {...successColorVariantMaybe} />
        </span>
        <span className={classNames(css.text, textClassName || css.textRoot)}>{label}</span>
      </label>
    </span>
  );
};

export default FieldCheckbox;
