import React, { useEffect, useState } from 'react';
import { arrayOf, bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { getDefaultTimeZoneOnBrowser } from '../../../../util/dates';
import { propTypes } from '../../../../util/types';
import {
  Button,
  H3,
  FieldCurrencyInput,
  IconSpinner,
  Icons,
  FieldSelect,
} from '../../../../components';
import CustomRangeInput from '../CustomRangeInput/CustomRangeInput';
import appSettings from '../../../../config/settings';
import css from './EditListingAvailabilityPanel.module.css';
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';
import {
  requestAddAvailabilityException,
  requestDeleteAvailabilityException,
} from '../../EditListingPage.duck';
import { isArrayLength } from '../../../../util/genericHelpers';
import { formatMoney } from '../../../../util/currency';
import { types as sdkTypes } from '../../../../util/sdkLoader';
import * as validators from '../../../../util/validators';
import { useIntl } from 'react-intl';
import { Form, Field } from 'react-final-form';

const defaultTimeZone = () =>
  typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC';

const { Money } = sdkTypes;

const getPriceValidators = (listingMinimumPriceSubUnits, marketplaceCurrency, intl) => {
  const priceRequiredMsgId = { id: 'EditListingPricingForm.priceRequired' };
  const priceRequiredMsg = intl.formatMessage(priceRequiredMsgId);
  const priceRequired = validators.required(priceRequiredMsg);

  const minPriceRaw = new Money(listingMinimumPriceSubUnits, marketplaceCurrency);
  const minPrice = formatMoney(intl, minPriceRaw);
  const priceTooLowMsgId = { id: 'EditListingPricingForm.priceTooLow' };
  const priceTooLowMsg = intl.formatMessage(priceTooLowMsgId, { minPrice });
  const minPriceRequired = validators.moneySubUnitAmountAtLeast(
    priceTooLowMsg,
    listingMinimumPriceSubUnits
  );

  return listingMinimumPriceSubUnits
    ? validators.composeValidators(priceRequired, minPriceRequired)
    : priceRequired;
};

const EditListingAvailabilityPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    onNextTab,
    onSubmit,
    allExceptions,
    addExceptionInProgress,
    isNewListingFlow,
    availabilityTab,
    viewport,
    unitType,
    marketplaceCurrency,
    listingMinimumPriceSubUnits,
  } = props;

  const dispatch = useDispatch();
  const intl = useIntl();

  const [showLoader, setLoader] = useState(false);
  const [cacheLoader, setCacheLoader] = useState(false);
  const [cacheLoaderReady, setCacheLoaderReady] = useState(false);
  const [deleteExceptionId, setDeleteExceptionId] = useState(null);
  const [clearDates, setClearDates] = useState(false);
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState('startDate');
  const [markUnavailable, onSetMarkUnavailable] = useState(false);

  const { publicData, createdAt, price } = listing?.attributes || {};

  const { instantBooking } = publicData || {};

  const classes = classNames(
    rootClassName || css.root,
    className,
    availabilityTab ? css.availabilityTab : null
  );

  const priceValidators = getPriceValidators(
    listingMinimumPriceSubUnits,
    marketplaceCurrency,
    intl
  );

  const defaultAvailabilityPlan = {
    type: 'availability-plan/time',
    timezone: defaultTimeZone(),
    entries: [
      { dayOfWeek: 'mon', startTime: '00:00', endTime: '00:00', seats: 1 },
      { dayOfWeek: 'tue', startTime: '00:00', endTime: '00:00', seats: 1 },
      { dayOfWeek: 'wed', startTime: '00:00', endTime: '00:00', seats: 1 },
      { dayOfWeek: 'thu', startTime: '00:00', endTime: '00:00', seats: 1 },
      { dayOfWeek: 'fri', startTime: '00:00', endTime: '00:00', seats: 1 },
      { dayOfWeek: 'sat', startTime: '00:00', endTime: '00:00', seats: 1 },
      { dayOfWeek: 'sun', startTime: '00:00', endTime: '00:00', seats: 1 },
    ],
  };

  const availabilityPlan = defaultAvailabilityPlan;

  const handleSubmit = values => {
    setCacheLoader(true);
    return onSubmit({
      id: listing?.id,
      availabilityPlan,
      price: values?.price || price,
      publicData: {
        instantBooking: values?.instantBooking,
      },
    })
      .then(() => {
        setCacheLoader(false);
        setCacheLoaderReady(true);
        if (isNewListingFlow) {
          onNextTab();
        }
      })
      .catch(e => {
        setCacheLoader(false);
        setCacheLoaderReady(false);
        // Don't close modal if there was an error
      });
  };

  useEffect(() => {
    if (!focusedInput) {
      setFocusedInput('startDate');
    }
  }, [focusedInput]);

  const isDateBlocked = day => {
    // Block dates before today
    if (day.isBefore(moment().startOf('day'))) {
      return true;
    }

    if (isArrayLength(allExceptions)) {
      const dayMoment = moment(day);

      // Check if the day is within any of the exception date ranges
      const isBlockedByExceptions = allExceptions?.some(exception => {
        const exceptionStart = moment(exception?.attributes?.start);
        const exceptionEnd = moment(exception?.attributes?.end);
        return dayMoment.isBetween(exceptionStart, exceptionEnd, 'day', '[]');
      });

      return isBlockedByExceptions;
    }
  };

  const renderAvailabilityContent = (
    <>
      <div className={css.sidebarBlock}>
        <div className={css.availableDays}>
          <span
            className={css.markAvailability}
            onClick={() => onSetMarkUnavailable(!markUnavailable)}
          >
            Mark as unavailable
            {markUnavailable ? <Icons name="redCircle" /> : <Icons name="blankCircle" />}
          </span>
          {markUnavailable ? (
            <div className={css.availabilityExceptionButtons}>
              <Button
                type="button"
                disabled={!endDate}
                inProgress={addExceptionInProgress}
                onClick={() => {
                  const isSingleDay = moment(endDate).diff(moment(startDate), 'days') === 0;

                  // Assuming startDate and endDate are in the same timezone
                  const startOfDay = moment(startDate)
                    .tz(defaultTimeZone())
                    .startOf('day')
                    .toDate();

                  const endOfDay = moment(endDate)
                    .tz(defaultTimeZone())
                    .toDate();
                  if (isSingleDay) {
                    endOfDay.setHours(23, 55, 0, 0);
                  } else {
                    endOfDay.setHours(0, 0, 0, 0);
                  }
                  dispatch(
                    requestAddAvailabilityException({
                      listingId: listing?.id,
                      start: startOfDay,
                      end: endOfDay,
                      seats: 0,
                    })
                  )
                    .then(() => {})
                    .catch(() => {})
                    .finally(() => {
                      setStartDate(null);
                      setEndDate(null);
                    });
                }}
              >
                Block Dates
              </Button>
              <Button
                disabled={!startDate}
                type="button"
                onClick={() => {
                  setStartDate(null);
                  setEndDate(null);
                }}
              >
                Clear
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );

  // Check if listing is older than the current date
  const isOldListing = createdAt ? new Date(createdAt) < new Date() : false;

  // Determine instantBooking behavior
  const computedInstantBooking = isNewListingFlow
    ? 'true'
    : isOldListing
    ? instantBooking ?? 'false' // Keep false/null values for old listings
    : instantBooking ?? 'true'; // Default new listings to true

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{
        price,
        instantBooking: computedInstantBooking,
      }}
      render={({ handleSubmit: finalFormHandleSubmit, values }) => (
        <form onSubmit={finalFormHandleSubmit}>
          <main className={classes}>
            <div className={css.pricingHeading}>
              <H3 as="h2">{intl.formatMessage({ id: 'EditListingPricingForm.priceHeading' })}</H3>
              <p className={css.priceDescription}>
                {intl.formatMessage({ id: 'EditListingPricingForm.priceDescription' })}
              </p>
            </div>
            <div className={css.customCalendarSec}>
              <FieldCurrencyInput
                id="price"
                name="price"
                className={css.input}
                label={intl.formatMessage(
                  { id: 'EditListingPricingForm.pricePerProduct' },
                  { unitType }
                )}
                placeholder={intl.formatMessage({
                  id: 'EditListingPricingForm.priceInputPlaceholder',
                })}
                currencyConfig={appSettings.getCurrencyFormatting(marketplaceCurrency)}
              />
            </div>
            <div className={css.customCalendarSec}>
              <FieldSelect
                id="instantBooking"
                className={css.quantityField}
                name="instantBooking"
                label={intl.formatMessage({ id: 'EditListingAvailabilityPanel.instantBooking' })}
              >
                <option disabled value="">
                  {intl.formatMessage({ id: 'EditListingAvailabilityPanel.selectOption' })}
                </option>
                <option value="true">
                  {intl.formatMessage({ id: 'EditListingAvailabilityPanel.instantBookingYes' })}
                </option>
                <option value="false">
                  {intl.formatMessage({ id: 'EditListingAvailabilityPanel.instantBookingNo' })}
                </option>
              </FieldSelect>

              {values?.instantBooking == 'true' && (
                <p className={css.instantBookingInfo}>
                  {intl.formatMessage({ id: 'EditListingAvailabilityPanel.instantBookingInfo' })}
                </p>
              )}
            </div>

            <div className={css.pricingHeading}>
              <H3 as="h2">
                {' '}
                {intl.formatMessage({ id: 'EditListingAvailabilityPanel.availabilityHeading' })}
              </H3>
              <p>
                {intl.formatMessage({ id: 'EditListingAvailabilityPanel.availabilityDescription' })}
              </p>
            </div>
            <div className={css.customCalendarSec}>
              <CustomRangeInput
                isAvailablityCalendar={true}
                onDatesChange={() => setClearDates(false)}
                numberOfMonths={2}
                clearDates={clearDates}
                allExceptions={allExceptions}
                startDate={startDate}
                endDate={endDate}
                isOutsideRange={isDateBlocked}
                setStartDate={d => {
                  setStartDate(d);
                  setEndDate(d);
                }}
                setEndDate={d => {
                  onSetMarkUnavailable(true);
                  setEndDate(d);
                }}
                focusedInput={focusedInput}
                setFocusedInput={setFocusedInput}
                className={css.customCalendar}
                viewport={viewport}
              />
              <aside className={css.sideBar}>{renderAvailabilityContent}</aside>
            </div>
            <div className={css.allExceptions}>
              <h4> {intl.formatMessage({ id: 'EditListingAvailabilityPanel.blockedDates' })}</h4>
              {isArrayLength(allExceptions)
                ? allExceptions?.map((a, i) => (
                    <div className={css.exceptionDates} key={i}>
                      <span className={css.startDate}>
                        {moment(a?.attributes?.start).format('MM/DD/YYYY')}
                      </span>
                      <span className={css.endDate}>
                        {moment(a?.attributes?.end).format('MM/DD/YYYY')}
                      </span>
                      <span
                        className={css.remove}
                        onClick={() => {
                          setLoader(true);
                          setDeleteExceptionId(a?.id?.uuid);
                          dispatch(
                            requestDeleteAvailabilityException({
                              id: a?.id,
                            })
                          ).then(() => {
                            setLoader(false);
                          });
                        }}
                      >
                        {showLoader && deleteExceptionId === a?.id?.uuid ? <IconSpinner /> : 'X'}
                      </span>
                    </div>
                  ))
                : null}
            </div>
            <div className={css.actionBtnSec}>
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={cacheLoader}
                ready={cacheLoaderReady}
              >
                {intl.formatMessage({
                  id: isNewListingFlow
                    ? 'EditListingAvailabilityPanel.nextPhotos'
                    : 'EditListingAvailabilityPanel.savePrice',
                })}
              </Button>
            </div>
          </main>
        </form>
      )}
    />
  );
};

export default EditListingAvailabilityPanel;
