import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { getAllTransitionsForEveryProcess } from '../../transactions/transaction';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { transitions } from '../../transactions/transactionProcessBooking';
import { mergeMessageAttributes } from '../../util/genericHelpers';

const sortedTransactions = txs => {
  const mergeMessageTxs = mergeMessageAttributes(txs); // If this enriches txs with message data, we keep it

  return reverse(
    sortBy(mergeMessageTxs, tx => {
      const lastTransitionedAt = new Date(tx.attributes?.lastTransitionedAt || 0).getTime();

      // Get the latest message creation time
      const messages = tx.relationships?.messages?.data || [];
      const latestMessageTime = messages?.reduce((latest, msg) => {
        const createdAt = new Date(msg.attributes?.createdAt || 0).getTime();
        return Math.max(latest, createdAt);
      }, 0);

      // Use the later of the two timestamps
      return Math.max(lastTransitionedAt, latestMessageTime);
    })
  );
};
  

// ================ Action types ================ //

export const FETCH_ORDERS_OR_SALES_REQUEST = 'app/InboxPage/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/InboxPage/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/InboxPage/FETCH_ORDERS_OR_SALES_ERROR';

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: false,
  fetchOrdersOrSalesError: null,
  pagination: null,
  transactionRefs: [],
};

export default function inboxPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ORDERS_OR_SALES_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      const transactions = sortedTransactions(payload);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }
    case FETCH_ORDERS_OR_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_OR_SALES_REQUEST });
const fetchOrdersOrSalesSuccess = response => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});
const fetchOrdersOrSalesError = e => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const INBOX_PAGE_SIZE = 10;

const filterTransactions = (transactions, isOrders) => {
  return isOrders
    ? transactions
    : transactions.filter(
        transaction =>
          transaction.attributes.lastTransition !== transitions.OPERATOR_REJECT_INSTANT_BOOKING &&
          transaction.attributes.lastTransition !== transitions.OPERATOR_REJECT_BOOKING &&
          transaction.attributes.lastTransition !== transitions.SUPERHOG_BACKGROUND_CHECKS_FAILED &&
          transaction.attributes.lastTransition !==
            transitions.INSTANT_SUPERHOG_BACKGROUND_CHECKS_FAILED
      );
};

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const { tab } = params;

  const onlyFilterValues = {
    orders: 'order',
    sales: 'sale',
  };

  const onlyFilter = onlyFilterValues[tab];
  if (!onlyFilter) {
    return Promise.reject(new Error(`Invalid tab for InboxPage: ${tab}`));
  }

  dispatch(fetchOrdersOrSalesRequest());

  const { page = 1 } = parse(search);

  const apiQueryParams = {
    only: onlyFilter,
    lastTransitions: getAllTransitionsForEveryProcess(),
    include: [
      'listing',
      'provider',
      'provider.profileImage',
      'customer',
      'customer.profileImage',
      'booking',
      'messages'
    ],
    'fields.transaction': [
      'processName',
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payinTotal',
      'metadata',
      'payoutTotal',
      'lineItems',
    ],
    'fields.listing': ['title', 'availabilityPlan', 'publicData.listingType'],
    'fields.user': [
      'profile',
      'profile.displayName',
      'profile.abbreviatedName',
      'deleted',
      'banned',
    ],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
    page,
    perPage: INBOX_PAGE_SIZE,
  };

  return sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      const isOrders = tab === 'orders';
      const filteredData = filterTransactions(response.data.data, isOrders);

      // Create a new response object with filtered data in the same structure
      const filteredResponse = {
        ...response,
        data: {
          ...response.data,
          data: filteredData,
        },
      };

      // Dispatch filtered data
      dispatch(addMarketplaceEntities(filteredResponse));
      dispatch(fetchOrdersOrSalesSuccess(filteredResponse));

      return filteredResponse;
    })
    .catch(e => {
      dispatch(fetchOrdersOrSalesError(storableError(e)));
      throw e;
    });
};
