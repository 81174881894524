import React from 'react';
import PropTypes from 'prop-types';
import { convertMarkdownToReact } from '../../util/markdownHelpers';
import './StrapiContentRenderer.css';

// Block Components
const Heading = ({ level, children }) => {
  const Tag = `h${level}`;
  return <Tag>{children}</Tag>;
};
const List = ({ format, children }) => {
  const Tag = format === 'ordered' ? 'ol' : 'ul';
  return <Tag>{children}</Tag>;
};
const Quote = ({ children }) => <blockquote>{children}</blockquote>;
const CodeBlock = ({ plainText }) => (
  <pre>
    <code>{plainText}</code>
  </pre>
);
const Image = ({ src, alt }) => <img src={src} alt={alt} />;
const Link = ({ url, children }) => (
  <a href={url} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
);

// Inline Modifier Renderer
const applyModifiers = (text, modifiers = {}) => {
  if (!text) return null;

  let result = convertMarkdownToReact(text);

  if (modifiers.code) {
    result = <code>{result}</code>;
  }
  if (modifiers.strikethrough) {
    result = <s>{result}</s>;
  }
  if (modifiers.underline) {
    result = <u>{result}</u>;
  }
  if (modifiers.italic) {
    result = <em>{result}</em>;
  }
  if (modifiers.bold) {
    result = <strong>{result}</strong>;
  }

  return result;
};

// Render children of a block node
const renderChildren = (children = []) =>
  children.map((child, index) => {
    if (child.type === 'text') {
      return <React.Fragment key={index}>{applyModifiers(child.text, child)}</React.Fragment>;
    }

    // Nested nodes like inline links
    return <React.Fragment key={index}>{renderNode(child)}</React.Fragment>;
  });

// Main node renderer
const renderNode = node => {
  switch (node.type) {
    case 'paragraph':
      return <p key={node.id || Math.random()}>{renderChildren(node.children)}</p>;

    case 'heading':
      return (
        <Heading key={node.id || Math.random()} level={node.level}>
          {renderChildren(node.children)}
        </Heading>
      );

    case 'list':
      return (
        <List key={node.id || Math.random()} format={node.format}>
          {node.children.map(renderNode)}
        </List>
      );

    case 'listItem':
      return <li key={node.id || Math.random()}>{renderChildren(node.children)}</li>;

    case 'quote':
      return <Quote key={node.id || Math.random()}>{renderChildren(node.children)}</Quote>;

    case 'code':
      return <CodeBlock key={node.id || Math.random()} plainText={node.plainText} />;

    case 'image':
      return (
        <Image
          key={node.id || Math.random()}
          src={node.image?.src || node.image?.url}
          alt={node.image?.alt || node.image?.alternativeText || ''}
        />
      );

    case 'link':
      return (
        <Link key={node.id || Math.random()} url={node.url}>
          {renderChildren(node.children)}
        </Link>
      );

    case 'text':
      return applyModifiers(node.text, node);

    default:
      return null;
  }
};

// Main renderer component
const StrapiContentRenderer = ({ content }) => {
  if (!content || !Array.isArray(content)) return null;
  return <>{content.map(renderNode)}</>;
};

StrapiContentRenderer.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default StrapiContentRenderer;
