export const MOORING_BUOY = 'mooringBuoy';
export const PUBLIC_MOORAGES = 'Public Moorages';

export const BOOKING_PROCESSES = {
  CHARITY_BOOKING_PROCESS_ALIAS: 'charity-booking/release-1',
  DEFAULT_BOOKING_PROCESS_ALIAS: 'default-booking/release-1',
};

//Note price is in cents
export const EXTENDED_PRICING_DATA = {
  FOR_LARGE_BUOY: 3000,
  FOR_LARGE_DOCK: 5000,
  FOR_BUOY: 3000,
  FOR_DOCK: 5000,
};

export const SCREENING_STATUS = {
  APPROVED: 'Approved',
  FLAGGED: 'Flagged',
  REJECTED: 'Rejeted',
};

export const DashboadPageNavOption = Object.freeze({
  USER_ACTIONS: 'user-actions',
  LISTING_ACTIVITY: 'listing-activity',
  INBOX_SUPERHOG: 'inbox-superhog',
  MESSSAGES: 'messages',
  TRANSACTIONS_DETAILS: 'transactions-details',
  HOST_STATUS_STRIPE: 'host-status-stripe',
  HOST_LISTINGS_SUMMARY: 'host-listigs-summary',
});
export const StatusCode = Object.freeze({
  SUCCESS: 200,
  CREATED: 201,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  CONFLICT: 409,
  INTERNAL_SERVER_ERROR: 500,
  SERVICE_UNAVAILABLE: 503,
  TOO_MANY_REQUESTS: 429,
});

export const UserDetailsOption = Object.freeze({
  USER_NAME: 'username',
  EMAIL: 'email',
  LAST_LOGIN: 'userLastLogin',
  DATE_JOINED: 'profileCreatedAt',
  USER_ID: 'userId',
});
export const HostStripeDetailsOption = Object.freeze({
  HOST_NAME: 'hostName',
  STATUS: 'status',
  BALANCE: 'balance',
  USER_ID: 'userId',
});
export const ListingActivityOption = Object.freeze({
  LISTING_ID: 'listingId',
  TITILE: 'title',
  STATUS: 'status',
  CREATED_AT: 'createdAt',
  LAST_MODIFIED_AT: 'latestModifiedAt',
  HOST_NAME: 'hostName',
  LAST_INSPECTION: 'inspection',
});
export const InboxSuperhogOption = Object.freeze({
  GUEST_NAME: 'guestName',
  HOST_NAME: 'hostName',
  TRANSACTION_ID: 'transactionId',
  MESSAGES: 'messages',
  LISTING_TITLE: 'listingTitle',
});
export const HostListingsSummaryOption = Object.freeze({
  HOST_NAME: 'hostName',
  USER_ID: 'userId',
  LISTINGS: 'listings',
  TRANSACTIONS_IN_PAST_12_MONTHS: 'transactionsInPast12Months',
});
export const TransactionDetailsPanelOption = Object.freeze({
  TRANSACTION_ID: 'transactionId',
  LISTING_TITLE: 'listingTitle',
  LISTING_URL: 'listingUrl',
  HOST_NAME: 'hostName',
  GUEST_NAME: 'guestName',
  RESERVATION_DATE: 'reservationDate',
  NIGHTS_BOOKED: 'nightsBooked',
  CUSTOMER_PAID_AMOUNT: 'customerPaidAmount',
  LAST_TRANSITION: 'lastTransition',
  TRANSITIONS: 'transitions',
  SUPERHOG_VERIFICATION_STATUS: 'superhogVerificationStatus',
  SUPERHOG_GUEST_SCREENING_ACTIONS: 'superhogGuestScreeningActions',
});

export const Environment = Object.freeze({
  PRODUCTION: 'production',
  DEVELOPMENT: 'development',
});

export const listingStatus = [
  { key: 'published', label: 'Publised' },
  { key: 'draft', label: 'Draft' },
  { key: 'deleted', label: 'Deleted' },
];

export const superhogStatusOptions = [
  { key: 'Approved', label: 'Approved' },
  { key: 'Flagged', label: 'Flagged' },
  { key: 'Rejected', label: 'Rejected' },
  { key: 'N/A', label: 'N/A' },
];

export const transitionStatusMap = {
  'transition/superhog-background-check-failed': 'Failed',
  'transition/instant-screening-failed': 'Failed',
  'transition/instant-superhog-background-check-failed': 'Failed',
  'transition/instant-superhog-background-check-passed': 'Passed',
  'transition/superhog-background-check-passed': 'Passed',
  'transition/expire': 'Expired',
  'transition/expire-payment': 'Expired',
  'transition/decline': 'Declined',
  'transition/operator-decline': 'Declined',
  'transition/accept': 'Accepted',
  'transition/operator-accept': 'Accepted',
  'transition/operator-reject-booking': 'Rejected',
  'transition/complete': 'Completed',
  'transition/operator-complete': 'Completed',
  'transition/superhog-background-check-started': 'Check Started',
  'transition/review-1-by-provider': 'Reviewed',
  'transition/review-2-by-provider': 'Reviewed',
  'transition/review-1-by-customer': 'Reviewed',
  'transition/review-2-by-customer': 'Reviewed',
  'transition/expire-customer-review-period': 'Review Period Expired',
  'transition/expire-provider-review-period': 'Review Period Expired',
  'transition/expire-review-period': 'Review Period Expired',
};

export const statusOptions = [
  { key: '', label: 'All Statuses' },
  { key: 'Failed', label: 'Failed' },
  { key: 'Expired', label: 'Expired' },
  { key: 'Declined', label: 'Declined' },
  { key: 'Accepted', label: 'Accepted' },
  { key: 'Passed', label: 'Passed' },
  { key: 'Rejected', label: 'Rejected' },
  { key: 'Completed', label: 'Completed' },
  { key: 'Check Started', label: 'Check Started' },
  { key: 'Reviewed', label: 'Reviewed' },
  { key: 'Review Period Expired', label: 'Review Period Expired' },
];

export const PhoneVerificationSteps = Object.freeze({
  Step_1: 0,
  Step_2: 1,
});