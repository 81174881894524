import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useField } from 'react-final-form';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';

import css from './MapFormField.module.css';
import { updateCoordinatesFields } from '../../util/genericHelpers';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
mapboxgl.workerClass = MapboxWorker;

const MapField = ({ name, values, formApi }) => {
  if (typeof window === 'undefined') {
    return null;
  }
  const {
    input: { value, onChange },
  } = useField(name);

  const [map, setMap] = useState(null);
  const [error, setError] = useState(null);
  const mapContainer = useRef(null);
  const marker = useRef(null);
  const geocoder = useRef(null);

  const reverseGeocode = async (lng, lat) => {
    const response = await axios.get(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${mapboxgl.accessToken}`
    );
    return response.data.features[0]?.place_name;
  };
  useEffect(() => {
    const initializeMap = ({ setMap, mapContainer }) => {
      let longitude =
        isNaN(value.longitude) || value.longitude < -180 || value.longitude > 180
          ? 0
          : value.longitude;
      let latitude =
        isNaN(value.latitude) || value.latitude < -90 || value.latitude > 90 ? 0 : value.latitude;

      const newMap = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [longitude, latitude],
        zoom: 5,
      });

      const newMarker = new mapboxgl.Marker({ draggable: true })
        .setLngLat([longitude, latitude])
        .addTo(newMap);

      newMarker.on('dragend', async () => {
        const lngLat = newMarker.getLngLat();

        if (lngLat.lat >= -90 && lngLat.lat <= 90 && lngLat.lng >= -180 && lngLat.lng <= 180) {
          const address = await reverseGeocode(lngLat.lng, lngLat.lat);
          onChange({
            longitude: lngLat.lng,
            latitude: lngLat.lat,
            address,
          });
          updateCoordinatesFields(lngLat.lat, lngLat.lng, formApi);
        } else {
          setError('Invalid latitude or longitude');
        }
      });

      newMap.on('load', () => {
        setMap(newMap);
        marker.current = newMarker;

        // Set initial value in geocoder if address exists
        if (values?.location?.address) {
          geocoder.current.setInput(values.location.address);
        }
      });

      geocoder.current = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
      });

      geocoder.current.on('result', function(e) {
        const [lng, lat] = e.result.geometry.coordinates;

        if (lat >= -90 && lat <= 90 && lng >= -180 && lng <= 180) {
          marker.current.setLngLat([lng, lat]);
          onChange({
            longitude: lng,
            latitude: lat,
            address: e.result?.place_name,
          });
          updateCoordinatesFields(lat, lng, formApi);
        } else {
          setError('Invalid latitude or longitude from geocoder result');
        }
      });

      document.getElementById('geocoder').appendChild(geocoder.current.onAdd(newMap));
    };

    if (!map) initializeMap({ setMap, mapContainer });
  }, [map]);

  const { longitude, latitude } = values?.location || { longitude: 0, latitude: 0 };
  const isValid = latitude >= -90 && latitude <= 90 && longitude >= -180 && longitude <= 180;

  if (marker.current && map && isValid) {
    if (marker.current.setLngLat) {
      marker.current.setLngLat([longitude || 0, latitude || 0]);
      map.flyTo({ center: [longitude || 0, latitude || 0] });
      marker.current.setDraggable(true);
    }
  }

  useEffect(() => {
    if (map) {
      map._markers.forEach(existingMarker => {
        if (!existingMarker._draggable) {
          existingMarker.remove();
        }
      });
    }
  });

  return (
    <div className={css.mapSection}>
      <div id="geocoder"></div>
      <div ref={el => (mapContainer.current = el)} style={{ width: '100%', height: '400px' }} />
      {error && <p className={css.error}>Latitude and longitude need to be between -180 and 180</p>}
    </div>
  );
};

export default MapField;
