import { ensurePaymentMethodCard, ensureStripeCustomer } from './data';
import { EXTENDED_PRICING_DATA, MOORING_BUOY } from './enums';
import { isArrayLength } from './genericHelpers';

const strapiBaseURL = process.env.REACT_APP_STRAPI_BASE_URL;

export const getUserLastSeen = user => {
  return !!user?.attributes?.profile?.protectedData?.lastSeenPending;
};

export const getUserBoatDetails = user => {
  const boatDetails = user?.attributes?.profile?.publicData?.boatDetails;
  return boatDetails;
};

export const getUserMembershipID = user => {
  const membershipID = user?.attributes?.profile?.protectedData?.membershipID;
  return membershipID;
};

export const isPayoutCharity = user => {
  return user?.attributes?.profile?.publicData?.selectedPayout === 'charity';
};

export const getCharityDetails = user => {
  return user?.attributes?.profile?.publicData?.charityOptions?.selectedOrganization;
};

export const getBoatPrice = (listing, currentUser) => {
  // Retrieve the maximum boat size for the current user
  const maxBoatSize = getUserBoatDetails(currentUser)?.length;

  // Check if the listing is for a mooring buoy
  const isBuoy = listing?.attributes?.publicData?.category === MOORING_BUOY;

  // Determine the pricing key based on boat size and listing type
  let pricingKey;
  if (!maxBoatSize) {
    pricingKey = isBuoy ? 'FOR_BUOY' : 'FOR_DOCK';
  } else {
    const isLargeBoat = Number(maxBoatSize) > 45;
    pricingKey = isLargeBoat
      ? isBuoy
        ? 'FOR_LARGE_BUOY'
        : 'FOR_LARGE_DOCK'
      : isBuoy
      ? 'FOR_BUOY'
      : 'FOR_DOCK';
  }

  // Return the corresponding pricing from the extended pricing data
  return EXTENDED_PRICING_DATA[pricingKey] / 100;
};

export const getUserDetails = user => {
  // Combine first and last names using optional chaining and nullish coalescing
  const fullName = user?.attributes?.profile?.firstName
    ? `${user?.attributes?.profile?.firstName} ${user?.attributes?.profile?.lastName}`
    : user?.attributes?.profile?.publicData?.fullName;

  // Use optional chaining and nullish coalescing for profile image
  const profileImage =
    user?.profileImage?.attributes?.variants?.default?.url ||
    user?.profileImage?.attributes?.variants?.['square-small2x']?.url ||
    null; // Set to null if no image variants found

  // Use optional chaining and nullish coalescing for email
  const email = user?.attributes?.email || user?.attributes?.profile?.publicData?.email;

  // Extract ID using optional chaining and nullish coalescing
  const id = user?.id?.uuid;
  const firstName = user?.attributes?.profile?.firstName;
  const lastName = user?.attributes?.profile?.lastName;
  const hasDefaultPaymentMethod =
    user?.id &&
    ensureStripeCustomer(user.stripeCustomer).attributes.stripeCustomerId &&
    ensurePaymentMethodCard(user.stripeCustomer.defaultPaymentMethod).id;
  const phoneNumber = user?.attributes?.profile?.protectedData?.phoneNumber;

  // Return object using destructuring assignment and computed property for clarity
  return {
    fullName,
    profileImage,
    email,
    id,
    firstName,
    lastName,
    hasDefaultPaymentMethod,
    phoneNumber,
  };
};

export const getBlogData = blog => {
  // Get the base URL from the configuration.
  const baseUrl = process.env.REACT_APP_STRAPI_BASE_URL;

  // Get the blog images and generate image URLs using the base URL.
  const blogImages = blog?.attributes?.images?.data || [];
  const imagesLink = blogImages?.length && getImagesLink(blogImages, baseUrl);

  // Get the blog title and description.
  const title = blog?.attributes?.title || '';
  const description = blog?.attributes?.description || '';
  const tags = blog?.attributes?.tags || '';
  const createdAt = blog?.attributes?.createdAt || '';

  // Return an object containing the blog title, description, and the first image URL (if available).
  return { title, description, tags, createdAt, image: imagesLink && imagesLink[0] };
};

export function getListingDetails(listing) {
  // Early return if listing has no valid ID
  if (!listing?.id?.uuid) return null; // Return null for invalid listings

  // Destructure listing data with default values
  const { id = null, attributes = {} } = listing || {};
  const { title = '', publicData = {} } = attributes;
  const { location = {}, petsAllowed = false } = publicData;

  // Return the extracted listing details
  return {
    listingId: id?.uuid,
    listingName: title,
    address: {
      addressLine1: 'New York',
      town: 'Mexico',
      countryIso: 'MX',
      postcode: '212932',
    },
    petsAllowed,
  };
}

export function getBookingDetails(booking) {
  // Early return if listing has no valid ID
  if (!booking?.id?.uuid) return null; // Return null for invalid listings

  // Destructure listing data with default values
  const { id = null, attributes = {} } = booking || {};
  const { start, end } = attributes;

  // Return the extracted listing details
  return {
    start,
    end,
    id,
  };
}

export const hasAllProperties = (obj, props) => {
  return props.every(prop => obj.hasOwnProperty(prop));
};
export const getVerficationId = tx => {
  return (
    tx?.attributes?.metadata?.superHogVerificationResponse?.verification?.verificationId || null
  );
};

export const getUserIndentity = user => {
  if (user && user?.id) {
    return user?.attributes?.profile?.publicData?.superhogIdentity;
  } else {
    return null;
  }
};

export const extractAndFormatBoundsFromString = queryString => {
  // Extract the query string part from the URL
  const queryParamsPart = queryString.split('?')[1];

  // Use URLSearchParams to parse the query parameters
  const params = new URLSearchParams(queryParamsPart);
  const bounds = params.get('bounds');

  // Check if bounds parameter exists
  if (!bounds) {
    console.log('Bounds parameter not found in the URL');
    return null;
  }

  // Split the bounds string into individual coordinates
  const coordinates = bounds.split(',');

  // Ensure we have exactly four coordinates for NE and SW bounds
  if (coordinates.length !== 4) {
    console.log('Invalid bounds format');
    return null;
  }

  // Format the coordinates: NE lat, NE lng, SW lat, SW lng
  const formattedBounds = coordinates.map(coord => coord.trim()).join(',');

  return formattedBounds;
};

export const getImagesLink = (images, url) => {
  // Generate an array of image URLs using the base URL and the image object's URL attribute.
  const imageUrls =
    isArrayLength(images) &&
    images.map(image => {
      const imageUrl = `${url}${image?.attributes?.url}`;
      return imageUrl;
    });

  // Return the array of image URLs.
  return imageUrls;
};
export const getUserFullName = user => {
  const firstName = user?.attributes?.profile?.firstName || '';
  const lastName = user?.attributes?.profile?.lastName || '';
  const fullName = `${firstName} ${lastName}`.trim();

  return fullName || 'N/A';
};

export const getPriceAndAssignListingType = listing => {
  return listing?.attributes?.publicData?.customPriceAmount;
};

export const getMarketingEventReminder = user => {
  return user?.attributes?.profile?.protectedData?.marketingEventReminder || false;
};

export const isPhoneNumberVerified = currentUser => {
  return currentUser?.attributes?.profile?.protectedData?.isPhoneNumberVerified;
};
export const getUserPhoneNumber = currentUser => {
  return currentUser?.attributes?.profile?.protectedData?.phoneNumber;
};
export const hidePhoneVerification = currentUser => {
  return currentUser?.attributes?.profile?.protectedData?.hidePhoneVerificationModal;
};

export const validateCustomListingPrice = price => {
  if (!price || price._sdkType !== 'Money') {
    return false; // Ensure price is valid
  }

  const { amount } = price;
  return amount === 3000 || amount === 5000 ? false : price;
};
