import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import loadable from '@loadable/component';
import {
  sendVerificationEmail,
  hasCurrentUserErrors,
  fetchCurrentUserHasListings,
  fetchCurrentUser,
} from '../../ducks/user.duck';
import { logout, authenticationInProgress } from '../../ducks/auth.duck';
import { manageDisableScrolling } from '../../ducks/ui.duck';
import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
import { isArrayLength } from '../../util/genericHelpers';
import { getUserLastSeen } from '../../util/dataExtractors';
import NotificationModal from './NotificationModal/NotificationModal';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routing/routeConfiguration';
import { PhoneVerificationModal } from '../../components';

const pageAsset = { banner: `content/pages/banner.json` };

const Topbar = loadable(() => import(/* webpackChunkName: "Topbar" */ './Topbar/Topbar'));

export const TopbarContainerComponent = props => {
  const {
    authInProgress,
    currentPage,
    currentSearchParams,
    currentUser,
    currentUserHasListings,
    currentUserHasOrders,
    history,
    isAuthenticated,
    authScopes,
    hasGenericError,
    location,
    notificationCount,
    onLogout,
    onManageDisableScrolling,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    onResendVerificationEmail,
    ...rest
  } = props;

  const dispatch = useDispatch();

  const [bannerAsset, setBannerAsset] = useState(null);
  const [showPhoneVerification, setShowPhoneVerification] = useState(false);

  const showNotificationModal = getUserLastSeen(currentUser);

  const bannerPageAsset = async () => {
    try {
      const asset = await dispatch(fetchPageAssets(pageAsset, true));
      const brandData =
        isArrayLength(asset?.banner?.data.sections) && asset?.banner?.data.sections[0];
      setBannerAsset(brandData);
    } catch (error) {
      console.log(error, 'Error while fetching banner page assets');
    }
  };

  useEffect(() => {
    bannerPageAsset();
    dispatch(fetchCurrentUser());
    dispatch(fetchCurrentUserHasListings());
  }, [showNotificationModal]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowPhoneVerification(true);
    }, 2000); // 2 seconds delay

    return () => clearTimeout(timeout); // Cleanup on unmount
  }, []);

  const notificationModal = (
    <NotificationModal
      isOpen={showNotificationModal}
      onCloseModal={async () => {
        await dispatch(
          updateProfile({
            protectedData: {
              lastSeenPending: false,
            },
          })
        );

        history.push(
          createResourceLocatorString(
            'InboxPage',
            routeConfiguration(),
            { tab: currentUserHasListings ? 'sales' : 'orders' },
            {}
          )
        );
      }}
      onManageDisableScrolling={onManageDisableScrolling}
    />
  );

  return (
    <>
      <Topbar
        authInProgress={authInProgress}
        currentPage={currentPage}
        currentSearchParams={currentSearchParams}
        currentUser={currentUser}
        currentUserHasListings={currentUserHasListings}
        currentUserHasOrders={currentUserHasOrders}
        history={history}
        isAuthenticated={isAuthenticated}
        authScopes={authScopes}
        location={location}
        notificationCount={showNotificationModal ? 1 : 0}
        onLogout={onLogout}
        bannerAsset={bannerAsset}
        onManageDisableScrolling={onManageDisableScrolling}
        onResendVerificationEmail={onResendVerificationEmail}
        sendVerificationEmailInProgress={sendVerificationEmailInProgress}
        sendVerificationEmailError={sendVerificationEmailError}
        showGenericError={hasGenericError}
        {...rest}
      />
      {notificationModal}
      {/* {showPhoneVerification && <PhoneVerificationModal />} */}
    </>
  );
};

const mapStateToProps = state => {
  // Topbar needs isAuthenticated
  const { isAuthenticated, logoutError, authScopes } = state.auth;
  // Topbar needs user info.
  const {
    currentUser,
    currentUserHasListings,
    currentUserHasOrders,
    currentUserNotificationCount: notificationCount,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
  } = state.user;
  const hasGenericError = !!(logoutError || hasCurrentUserErrors(state));
  return {
    authInProgress: authenticationInProgress(state),
    currentUser,
    currentUserHasListings,
    currentUserHasOrders,
    notificationCount,
    isAuthenticated,
    authScopes,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    hasGenericError,
  };
};

const mapDispatchToProps = dispatch => ({
  onLogout: historyPush => dispatch(logout(historyPush)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const TopbarContainer = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(TopbarContainerComponent);

export default TopbarContainer;
