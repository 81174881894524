import { updateProfile } from '../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import { addContactMailchimpApi, sendOneTimePassword, validateOneTimePassword } from '../util/api';

// Action Types
export const SEND_OTP_REQUEST = 'app/global/SEND_OTP_REQUEST';
export const SEND_OTP_SUCCESS = 'app/global/SEND_OTP_SUCCESS';
export const SEND_OTP_FAILURE = 'app/global/SEND_OTP_FAILURE';

export const VERIFY_OTP_REQUEST = 'app/global/VERIFY_OTP_REQUEST';
export const VERIFY_OTP_SUCCESS = 'app/global/VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_FAILURE = 'app/global/VERIFY_OTP_FAILURE';

export const ADD_CONTACT_SENDGRID_REQUEST = 'app/global/ADD_CONTACT_SENDGRID_REQUEST';
export const ADD_CONTACT_SENDGRID_SUCCESS = 'app/global/ADD_CONTACT_SENDGRID_SUCCESS';
export const ADD_CONTACT_SENDGRID_FAILURE = 'app/global/ADD_CONTACT_SENDGRID_FAILURE';

// Action Creators
const sendOtpRequest = () => ({ type: SEND_OTP_REQUEST });
const sendOtpSuccess = data => ({ type: SEND_OTP_SUCCESS, payload: data });
const sendOtpFailure = error => ({ type: SEND_OTP_FAILURE, payload: error });

const verifyOtpRequest = () => ({ type: VERIFY_OTP_REQUEST });
const verifyOtpSuccess = data => ({ type: VERIFY_OTP_SUCCESS, payload: data });
const verifyOtpFailure = error => ({ type: VERIFY_OTP_FAILURE, payload: error });

const addContactSendgridRequest = () => ({ type: ADD_CONTACT_SENDGRID_REQUEST });
const addContactSendgridSuccess = data => ({ type: ADD_CONTACT_SENDGRID_SUCCESS, payload: data });
const addContactSendgridFailure = error => ({ type: ADD_CONTACT_SENDGRID_FAILURE, payload: error });

// Thunk Actions
export const sendOtpToMobile = body => async dispatch => {
  dispatch(sendOtpRequest());
  try {
    const response = await sendOneTimePassword(body);
    dispatch(sendOtpSuccess(response.data));
    return response;
  } catch (error) {
    dispatch(sendOtpFailure(error.message));
    return error;
  }
};

export const verifyOneTimePassword = body => async dispatch => {
  dispatch(verifyOtpRequest());
  try {
    const response = await validateOneTimePassword(body);
    dispatch(verifyOtpSuccess(response.data));

    if (response?.statusCode === 200) {
      dispatch(
        updateProfile({
          protectedData: {
            isPhoneNumberVerified: true,
            phoneNumber: body?.phone,
          },
        })
      );
    }
    return response;
  } catch (error) {
    dispatch(verifyOtpFailure(error.message));
    return error;
  }
};

export const addContactSendgrid = body => async dispatch => {
  dispatch(addContactSendgridRequest());
  try {
    const response = await addContactMailchimpApi(body);
    dispatch(addContactSendgridSuccess(response.data));
    return response;
  } catch (error) {
    dispatch(addContactSendgridFailure(error.message));
    return error;
  }
};

// Initial State
const initialState = {
  otpLoading: false,
  otpData: null,
  otpError: null,
  verifyLoading: false,
  verifyData: null,
  verifyError: null,
  addContactLoading: false,
  addContactData: null,
  addContactError: null,
};

// Reducer
const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEND_OTP_REQUEST:
      return { ...state, otpLoading: true, otpError: null };
    case SEND_OTP_SUCCESS:
      return { ...state, otpLoading: false, otpData: action.payload };
    case SEND_OTP_FAILURE:
      return { ...state, otpLoading: false, otpError: action.payload };
    case VERIFY_OTP_REQUEST:
      return { ...state, verifyLoading: true, verifyError: null };
    case VERIFY_OTP_SUCCESS:
      return { ...state, verifyLoading: false, verifyData: action.payload };
    case VERIFY_OTP_FAILURE:
      return { ...state, verifyLoading: false, verifyError: action.payload };
    case ADD_CONTACT_SENDGRID_REQUEST:
      return { ...state, addContactLoading: true, addContactError: null };
    case ADD_CONTACT_SENDGRID_SUCCESS:
      return { ...state, addContactLoading: false, addContactData: action.payload };
    case ADD_CONTACT_SENDGRID_FAILURE:
      return { ...state, addContactLoading: false, addContactError: action.payload };
    default:
      return state;
  }
};

export default globalReducer;
