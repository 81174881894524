import React, { useEffect } from 'react';
import { DateRangePicker } from 'react-dates';
import css from './CustomRangeInput.module.css';
import 'react-dates/initialize';
import '../../../../styles/_datepicker.css';

const CustomRangeInput = props => {
  const {
    className,
    isAvailablityCalendar,
    onDatesChange,
    clearDates,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    focusedInput,
    setFocusedInput,
    isOutsideRange,
    numberOfMonths,
  } = props;

  const handleDateChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
    onDatesChange({ startDate, endDate });
  };

  useEffect(() => {
    if (clearDates) {
      setStartDate(null);
      setEndDate(null);
    }
  }, [clearDates]);

  const orientation =
    typeof window !== 'undefined' && window.matchMedia('(max-width: 1023px)')?.matches
      ? 'vertical'
      : 'horizontal';

  return (
    <div className={isAvailablityCalendar ? css.availablityCalendar : className}>
      <DateRangePicker
        startDate={startDate}
        startDateId="start_date_id"
        endDate={endDate}
        endDateId="end_date_id"
        minimumNights={0}
        numberOfMonths={numberOfMonths}
        onDatesChange={handleDateChange}
        orientation={orientation}
        isOutsideRange={isOutsideRange}
        hideKeyboardShortcutsPanel
        focusedInput={focusedInput} // Set the focusedInput prop to "startDate" to always keep the DateRangePicker open
        onFocusChange={focusedInput => setFocusedInput(focusedInput)}
        keepOpenOnDateSelect // Enable the keepOpenOnDateSelect prop to keep the picker open after date selection
      />
    </div>
  );
};

export default CustomRangeInput;
