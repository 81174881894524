import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PhoneInput, { formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input';
import OtpInput from 'react-otp-input';
import { Button, Icons, IconSpinner, Modal } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { sendOtpToMobile, verifyOneTimePassword } from '../../ducks/global.duck';
import { onTriggerError, onTriggerSuccess } from '../../util/toast';
import { currentUserSelector } from '../../ducks/user.duck';
import {
  getUserPhoneNumber,
  hidePhoneVerification,
  isPhoneNumberVerified,
} from '../../util/dataExtractors';
import { manageDisableScrolling } from '../../ducks/ui.duck';
import { useIntl } from 'react-intl';
import {
  selectProfileUpdateInProgress,
  updateProfile,
} from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import { selectIsAuthenticated } from '../../ducks/auth.duck';

import '../../styles/react-phone.css';
import css from './PhoneVerificationModal.module.css';
import { PhoneVerificationSteps, StatusCode } from '../../util/enums';

const TWILIO_FLOW = process.env.REACT_APP_TWILIO_FLOW === 'true';

const PhoneVerificationModal = ({
  isOpen = false,
  onClose = () => {},
  defaultCurrentStep = 0,
  defaultPhoneNumber = '',
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [currentStep, setCurrentStep] = useState(PhoneVerificationSteps?.Step_1);
  const [errorMessage, setErrorMessage] = useState('');

  const { otpLoading, verifyLoading } = useSelector(state => state.global);
  const currentUser = useSelector(currentUserSelector);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const updateInProgress = useSelector(selectProfileUpdateInProgress);
  const isPhoneVerified = isPhoneNumberVerified(currentUser);
  const savedPhoneNumberMaybe = getUserPhoneNumber(currentUser);
  const hidePhoneVerificationModal = hidePhoneVerification(currentUser);

  useEffect(() => {
    setCurrentStep(defaultCurrentStep ?? PhoneVerificationSteps?.Step_1);
    setPhoneNumber(defaultPhoneNumber ?? savedPhoneNumberMaybe ?? '');
  }, [defaultPhoneNumber, defaultCurrentStep]);

  const handlePhoneNumberSubmit = async () => {
    if (!phoneNumber || !isValidPhoneNumber(phoneNumber)) {
      onTriggerError('Please enter a valid phone number');
      return;
    }

    try {
      if (TWILIO_FLOW) {
        // Twilio OTP flow
        const data = await dispatch(sendOtpToMobile({ phone: phoneNumber }));
        if (data?.statusCode === StatusCode?.SUCCESS) {
          onTriggerSuccess('OTP sent successfully');
          setCurrentStep(PhoneVerificationSteps?.Step_2);
        } else {
          onTriggerError(data?.message);
        }
      } else {
        // Direct save flow
        await dispatch(
          updateProfile({
            protectedData: {
              isPhoneNumberVerified: false,
              hidePhoneVerificationModal: true,
              phoneNumber: phoneNumber,
            },
          })
        );
        onTriggerSuccess('Phone number saved successfully');
        onClose();
        setPhoneNumber('');
        setCurrentStep(PhoneVerificationSteps?.Step_1);
      }
    } catch (error) {
      onTriggerError(TWILIO_FLOW ? 'Failed to send OTP' : 'Failed to save phone number');
    }
  };

  const handleOtpSubmit = async () => {
    try {
      const data = await dispatch(verifyOneTimePassword({ phone: phoneNumber, otp: otp }));
      if (data?.statusCode === StatusCode?.SUCCESS) {
        onTriggerSuccess('OTP verified successfully');
        onClose();
        setOtp('');
        setPhoneNumber('');
        setCurrentStep(PhoneVerificationSteps?.Step_1);
      } else {
        onTriggerError(data?.message);
      }
    } catch (error) {
      onTriggerError('Failed to verify OTP');
    }
  };

  const onCloseModal = () => {
    onClose();
    dispatch(
      updateProfile({
        protectedData: {
          hidePhoneVerificationModal: true,
        },
      })
    );
  };

  const renderStepContent = step => {
    // If TWILIO_FLOW is false, we only need the phone number input step
    if (!TWILIO_FLOW && step === 1) return null;

    switch (step) {
      case 0:
        return (
          <div className={css.verificationCodeWrapper}>
            <div className={css.phoneNumberWrapper}>
              <label className={css.phoneNumberLabel}>
                {intl.formatMessage({ id: 'PhoneNumberModal.phoneNumberLabel' })}
              </label>
              <PhoneInput
                format={formatPhoneNumberIntl}
                className={css.fieldTextInput}
                placeholder={intl.formatMessage({
                  id: 'PhoneNumberModal.phoneNumberPlaceholder',
                })}
                defaultCountry="US"
                value={phoneNumber || savedPhoneNumberMaybe}
                onChange={value => {
                  setPhoneNumber(value);
                  const formattedNumber = formatPhoneNumberIntl(value);
                  if (isValidPhoneNumber(formattedNumber)) {
                    setErrorMessage('');
                  } else {
                    setErrorMessage('Invalid phone number');
                  }
                }}
              />
            </div>
            <div className={css.btnWrapper}>
              <Button onClick={handlePhoneNumberSubmit} disabled={!phoneNumber || otpLoading}>
                {otpLoading
                  ? intl.formatMessage({ id: 'PhoneNumberModal.sendOtpBtn1' })
                  : TWILIO_FLOW
                  ? intl.formatMessage({ id: 'PhoneNumberModal.sendOtpBtn2' })
                  : intl.formatMessage({ id: 'PhoneNumberModal.savePhoneNumberBtn' })}
              </Button>
            </div>
          </div>
        );
      case 1:
        return (
          <div className={css.verificationCodeWrapper}>
            <label className={css.verificationCodeLabel}>Enter code</label>
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              shouldAutoFocus
              isInputNum
              containerStyle={css.otpContainer}
              inputStyle={css.otpInput}
              renderInput={props => <input {...props} />}
            />
            <div className={css.btnWrapper}>
              <Button onClick={handleOtpSubmit} disabled={!otp || verifyLoading}>
                {verifyLoading
                  ? intl.formatMessage({ id: 'PhoneNumberModal.verifyOtpBtn1' })
                  : intl.formatMessage({ id: 'PhoneNumberModal.verifyOtpBtn2' })}
              </Button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Modal
      id="VerifyPhoneNumber"
      isOpen={isAuthenticated && ((!hidePhoneVerificationModal && !isPhoneVerified) || isOpen)}
      onClose={onCloseModal}
      onManageDisableScrolling={(componentId, disableScrolling) =>
        dispatch(manageDisableScrolling(componentId, disableScrolling))
      }
    >
      <div className={css.verificationWrapper}>
        {updateInProgress ? (
          <div className={css.spinnerWrapper}>
            <IconSpinner />
          </div>
        ) : (
          <div className={css.verifyForm}>
            {renderStepContent(currentStep)}
            {errorMessage && (
              <div className={css.errorWrapper}>
                <Icons name="error" />
                <p className={css.errorMessage}>{errorMessage}</p>
              </div>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};

PhoneVerificationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PhoneVerificationModal;
