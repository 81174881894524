import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from '../../util/reactIntl';
import { ExternalLink } from '../../components';
import { Icons } from '../../components';
import { LinkedLogo } from '../../components';
import { usePWAInstall } from 'react-use-pwa-install';

import css from './FooterContainer.module.css';

const MAX_MOBILE_SCREEN_WIDTH = 768;
const ROOT_URL = process.env.REACT_APP_MARKETPLACE_ROOT_URL;

function FooterSection({ titleId, links, target = '_blank' }) {
  const intl = useIntl();
  return (
    <div className={css.linkBlock}>
      <h2>{intl.formatMessage({ id: titleId })}</h2>
      {links.map((link, index) => (
        <a
          key={index}
          className={css.navLink}
          target={link?.target || target}
          href={link.name}
          rel="noopener noreferrer"
        >
          {intl.formatMessage({ id: link.labelId })}
        </a>
      ))}
    </div>
  );
}

function FooterContainer() {
  const intl = useIntl();
  const deferredPrompt = useRef(null);
  const [isMobileLayout, setIsMobileLayout] = useState(false);
  const installPWA = usePWAInstall();

  useEffect(() => {
    const handleResize = () => {
      setIsMobileLayout(window.innerWidth < MAX_MOBILE_SCREEN_WIDTH);
    };

    if (typeof window !== 'undefined') {
      handleResize();
      window.addEventListener('resize', handleResize);
      window.addEventListener('beforeinstallprompt', e => {
        e.preventDefault();
        deferredPrompt.current = e;
      });
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const addToHomeScreen = () => {
    if (deferredPrompt.current) {
      deferredPrompt.current.prompt();
      deferredPrompt.current.userChoice.then(choiceResult => {
        deferredPrompt.current = null;
      });
    }
  };

  const companyLinks = [
    { name: `${ROOT_URL}/blog`, labelId: 'FooterContainer.link.blog', target: '_self' },
    { name: `${ROOT_URL}/locations`, labelId: 'FooterContainer.link.locations', target: '_self' },
    {
      name: `${ROOT_URL}/help/general-faqs/3/how-onbuoy-benefits-the-environment`,
      labelId: 'FooterContainer.link.environment',
    },
    { name: `${ROOT_URL}/p/become-a-host`, labelId: 'FooterContainer.link.becomeHost' },
  ];

  const communityLinks = [
    { name: `${ROOT_URL}/help`, labelId: 'FooterContainer.link.faq' },
    { name: `${ROOT_URL}/help/host-toolkit`, labelId: 'FooterContainer.link.toolkit' },
    {
      name: `${ROOT_URL}/help/general-faqs/5/what-is-onbuoy`,
      labelId: 'FooterContainer.link.whatIs',
    },
    {
      name: `${ROOT_URL}/help/general-faqs/74/contact-us`,
      labelId: 'FooterContainer.link.contact',
    },
  ];

  const topLocationsLinks = [
    {
      name: `${ROOT_URL}/s?address=&bounds=48.88869801%2C-121.13315565%2C46.80367813%2C-124.09667354&mapSearch=true&search=`,
      labelId: 'FooterContainer.link.pugetSound',
    },
    {
      name: `${ROOT_URL}/s?address=Seattle%2C%20Washington%2C%20United%20States&bounds=47.7379309%2C-122.215854%2C47.4772268%2C-122.4628463&search=Seattle%2C%20Washington%2C%20United%20States`,
      labelId: 'FooterContainer.link.seattle',
    },
    {
      name: `${ROOT_URL}/s?address=Washington%20State%20Parks%2C%20Blake%20Island%2C%20Port%20Orchard%2C%20Washington%2098353%2C%20United%20States&bounds=47.61546456%2C-122.3722483%2C47.4594461%2C-122.59265269&mapSearch=true&search=Washington%20State%20Parks%2C%20Blake%20Island%2C%20Port%20Orchard%2C%20Washington%2098353%2C%20United%20States`,
      labelId: 'FooterContainer.link.blakeIsland',
    },
  ];

  return (
    <div className={css.footer}>
      <div className={css.contentWidth}>
        <div className={css.footerTop}>
          <FooterSection
            titleId="FooterContainer.title.explore"
            target="_self"
            links={companyLinks}
          />
          <FooterSection
            titleId="FooterContainer.title.help"
            target="_self"
            links={communityLinks}
          />
          {!isMobileLayout && (
            <FooterSection titleId="FooterContainer.title.topLocations" links={topLocationsLinks} />
          )}

          <div className={css.linkBlock}>
            <h2>{intl.formatMessage({ id: 'FooterContainer.connectWithUs' })}</h2>
            <div className={css.socialLinks}>
              <ExternalLink href="https://www.facebook.com/OnBuoySPC" className={css.navLink}>
                <Icons name="facebook" />
              </ExternalLink>
              <ExternalLink href="https://www.instagram.com/onbuoyspc" className={css.navLink}>
                <Icons name="instagram" />
              </ExternalLink>
              <ExternalLink href="https://www.linkedin.com/company/onbuoy/" className={css.navLink}>
                <Icons name="linkedin" />
              </ExternalLink>
              <ExternalLink href="https://www.youtube.com/@OnBuoy" className={css.navLink}>
                <Icons name="youtube" />
              </ExternalLink>
              {installPWA && (
                <div onClick={installPWA} className={css.navLink}>
                  <Icons name="toolsIcon" />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className={css.footerBottom}>
          <LinkedLogo
            className={css.logoLink}
            layout="desktop"
            alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
          />
          <div className={css.rightLinks}>
            <p>{intl.formatMessage({ id: 'FooterContainer.ARR' })}</p>
            <a className={css.navLink} href="/privacy-policy">
              {intl.formatMessage({ id: 'FooterContainer.privacyPolicy' })}
            </a>
            <a className={css.navLink} href="/terms-of-service">
              {intl.formatMessage({ id: 'FooterContainer.termsOfUse' })}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FooterContainer;
